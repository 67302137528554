.transport-details {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: .6rem;
  background-color: $cl-default-lightest;
  overflow: hidden;
}

.transport-details__location-label {
  z-index: 2;
  position: relative;
  display: flex;
  align-items: center;
  padding: 3rem 3.2rem;
  font-weight: $font-weight--bold;
  color: $cl-default;
  background-color: $cl-default-lightest;

  &::after {
    content: '';
    position: absolute;
    width: 3.2rem;
    height: 100%;
  }

  &--origin {
    &::after {
      right: -3.2rem;
      background: linear-gradient(to right, $cl-default-lightest, rgba($cl-default-lightest, 0));
    }
  }

  &--destination {
    &::after {
      left: -3.2rem;
      background: linear-gradient(to left, $cl-default-lightest, rgba($cl-default-lightest, 0));
    }
  }

  &[data-disabled="true"] {
    color: rgba($cl-default, .5);

    .transport-details__location-icon {
      opacity: .5;
    }
  }
}

.transport-details__location-icon {
  margin-right: .4rem;
}

.transport-details__distance {
  z-index: 2;
  position: absolute;
  left: 50%;
  display: flex;
  align-items: center;
  padding: 3rem 3.2rem;
  font-weight: $font-weight--bold;
  color: $cl-default;
  background: linear-gradient(
    to left,
    rgba($cl-default-lightest, 0),
    $cl-default-lightest 1.6rem,
    $cl-default-lightest calc(100% - 1.6rem),
    rgba($cl-default-lightest, 0)
  );
  transform: translateX(-50%);

  &[data-disabled="true"] {
    color: rgba($cl-default, .5);
  }
}

.transport-details__dashed-line {
  z-index: 1;
  position: absolute;
  left: 0;
  width: 100%;
  height: .2rem;
  background: url('../../images/icons/dashed-line.svg');
  background-position: 0 0;
  background-repeat: repeat-x;

  &.animated {
    animation: animateDashedLine 1s linear infinite;
  }

  &[data-disabled="true"] {
    opacity: .5;
  }
}

@keyframes animateDashedLine {
  to {
    transform: translateX(1rem);
  }
}
