@use 'sass:math';

.rating {
  display: flex;
  align-items: center;
}

.rating__stars {
  display: flex;
  height: 1.6rem;
  mask-image: url("../../images/icons/rating-star.svg"); // This doesn't work in IE11, should think of another method or polyfill
  background-color: rgba($cl-white, .25);
  transition: background-color $transition-duration;
}

.rating__stars-inner {
  mask-image: url("../../images/icons/rating-star.svg");
  background-color: $cl-warning;
  transition: {
    property: width, background-color;
    duration: $transition-duration;
  }
}

.rating__number {
  margin-left: math.div($spacing, 2);
  font-weight: $font-weight--bold;
}
