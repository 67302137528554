.action-bar {
  display: flex;

  > * {
    width: auto;
    flex-grow: 0;

    &:first-child {
      margin-left: auto;
    }

    + * {
      margin-left: $gutter * .5;
    }
  }
}
