@media print {
  body {
    font-size: 1.2rem !important;
    background: none !important;

    .xxs12 {
      width: 50%;
    }

    .spinner--sync {
      display: none;
    }

    .header {
      color: $cl-black;
      background: none;

      h1,
      h4 {
        color: $cl-black;
      }
    }

    .header__heading {
      font-size: 2rem;
    }

    .header__subheading {
      font-size: 1.6rem;
    }

    .header__content {
      padding: 0 0 $gutter 0;
    }

    .header-bar {
      display: none;
    }

    .container {
      max-width: 100%;
    }

    .compared-value {
      opacity: 0;
    }

    .card {
      min-height: unset;
      margin: 0 0 $spacing 0;
      padding: 0 0 $spacing 0;
      border-radius: 0;
      border-bottom: .1rem solid $cl-grey-dark;
      box-shadow: none;

      > .group {
        margin-bottom: 0;
      }
    }

    .card-headings {
      margin: 0;
    }

    h4 {
      font-size: 1.6rem;
      margin-bottom: 1rem;
    }

    h5 {
      font-size: 1.4rem;
    }

    h6 {
      font-size: 1.2rem;
    }

    .card__column {
      margin-bottom: 0;
    }

    .result-labels {
      display: none;
    }

    .hide-print {
      display: none;
    }

    .card-list {
      margin: 0;
    }

    .card-list__column {
      font-size: 1.2rem;
    }

    .chip {
      color: $cl-black;
      background-color: $cl-white;
      border: .1rem solid $cl-black;
    }

    .result-labels {
      margin-bottom: 2rem;
      transform: translate3d(0, 0, 0);

      .result-labels__item,
      .result-labels__item:first-child {
        padding: 0 1rem 0 0;
        border-radius: 0;
        color: $cl-black;
        background: none;
      }
    }

    .header__content {
      padding: 2rem 0;
    }

    .textarea {
      display: none;
    }

    .button {
      display: none !important;
    }
  }

  .main-form {

    .group > * {
      display: block;
    }

    .md6 {
      width: 50%;
    }

    .md3 {
      width: 25%;
    }

    .input__container,
    .textarea__element,
    .select__container,
    .input__prepend,
    .input__append,
    .react-select__control {
      box-shadow: none !important;
      border: none;
      padding: 0;
      background: none !important;
    }

    .react-datepicker__input-container::after,
    .select .react-select__dropdown-indicator,
    .chip--disabled {
      display: none;
    }

    .input__element,
    .select .react-select__value-container {
      font-size: 1.4rem;
      padding: 0 !important;
    }
  }
}
