.attachments {

}

.attachments__dropzone {
  display: flex;
  justify-content: center;
  padding: 3.2rem;
  border: .2rem dashed $cl-grey;
  border-radius: .6rem;
  background-color: $cl-grey-lighter;

  &:focus {
    outline: none;
    border: .2rem solid $cl-grey-dark;
  }
}

.attachments-list {

}

.attachments-list__item {
  display: flex;
  align-items: center;
  padding: 1.6rem 0;
  min-height: 8rem;

  + .attachments-list__item {
    border-top: .1rem solid $cl-grey-light;
  }
}

.attachments-list__item-name {
  display: flex;
  align-items: center;

  svg {
    margin-right: .8rem;
  }
}

.attachments-list__item-actions {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.attachments-list__throbber {
  animation: rotateThrobber 1s infinite linear;
}

@keyframes rotateThrobber {
  to {
    transform: rotate(360deg);
  }
}
