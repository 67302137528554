@keyframes fadeIn {
  from {
    opacity: 0;
  } to {
    opacity: 1;
  }
}

.tabs {
  min-height: 30rem;

  .react-tabs__tab-list {
    display: flex;
    margin: 0 0 $spacing;
    padding: 0;
    list-style: none;
    border: none;
    box-shadow: 0 .1rem 0 0 $cl-grey-light;
  }

  .react-tabs__tab-panel {
    display: none;
  }

  .react-tabs__tab-panel--selected {
    display: block;
  }

  .react-tabs__tab {
    cursor: pointer;
    margin-right: $gutter;
    padding: $spacing 0 2rem;
    line-height: 1.25;
  }

  .react-tabs__tab--selected {
    border: none;
    color: $cl-primary;
    font-weight: $font-weight--bold;
    box-shadow: 0 -.4rem 0 0 $cl-primary inset;
    border-radius: 0;
  }
}

.tab-container {
  .react-tabs__tab-list {
    display: flex;
    align-items: center;
    width: 100%;
    list-style: none;
    padding: 0;
    margin: .8rem 0 0;
    box-shadow: 0 -1px 0 $cl-grey inset;
  
    .react-tabs__tab {
      padding-bottom: .8rem;
      cursor: pointer;
      user-select: none;
  
      + .react-tabs__tab {
        margin-top: 0;
        margin-left: 1.6rem;
      }

      &:hover {
        box-shadow: 0 -2px 0 $cl-primary inset;
      }
    }

    .react-tabs__tab--selected {
      box-shadow: 0 -2px 0 $cl-primary inset;
      color: $cl-primary;
    }
  }

  .tab-content {
    h5 {
      margin-bottom: 1.6rem;
    }
  }
}
