@use 'sass:math';

.form-group {
  margin-bottom: 2.4rem;

  .modal__box-content > &:first-child {
    padding-top: $spacing;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .input__label,
  .select__label {
    line-height: 1;
  }

  .radio {
    margin-bottom: math.div($spacing, 2);
  }

  + .form-group {
    .form-group__title {
      margin-top: 3.2rem;
    }
  }

  .button--circle {
    float: right;
  }
}

.form-group--indented {
  position: relative;
  padding-left: 3rem;

  &::before {
    content: '';
    position: absolute;
    top: -1rem;
    left: 0;
    width: 1.5rem;
    height: 2rem;
    border: .2rem solid $cl-grey-alt;
    border-right: 0;
    border-top: 0;
    border-bottom-left-radius: .6rem;

  }

  &::after {
    content: '';
    position: absolute;
    top: .3rem;
    left: 1.5rem;
    width: 0;
    height: 0;
    border-top: .6rem solid transparent;
    border-bottom: .6rem solid transparent;
    border-left: .6rem solid $cl-grey-alt;
  }
}

.form-group__label {
  display: block;
  margin-bottom: $spacing;
  font-weight: $font-weight--bold;
  line-height: 1;
}

.form-group__title {
  font-weight: $font-weight--bold;
  margin-bottom: 2.4rem;
}

.form-group__row {
  display: flex;

  > * + * {
    margin-left: 3.2rem;
  }

  .radio__label {
    margin-left: .8rem;
  }
}
