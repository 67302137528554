@use 'sass:math';

body:after {
  @extend %depth-10;
  position: fixed;
  right: .5rem;
  bottom: .5rem;
  display: none;
  padding: .5rem 1rem;
  border-radius: 2rem;
  color: $cl-white;
  background-color: rgba($cl-black, .6);
}

.grid-overlay {
  @extend %depth-10;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  user-select: none;

  &.is-visible {
    visibility: visible;
  }

  .container,
  .group {
    height: 100%;
  }

  .group > div {
    height: 100%;
    background-color: rgba(red, .2);

    > div {
      height: 100%;
      color: $cl-white;
      text-align: center;
      background-color: rgba(red, .4);
    }
  }
}

.grid-button {
  @extend %depth-10;
  position: fixed;
  left: .5rem;
  bottom: .5rem;
  padding: 1rem 2rem;
  color: $cl-white;
  font-size: 1.4rem;
  background-color: $cl-black;
  border-radius: 3rem;
  outline: none;
}

.group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 math.div(-$gutter, 2);
  padding: 0 .8rem;

  > .flex {
    display: flex;

    button + button {
      margin-left: 1.6rem;
    }
  }

  > .align-right {
    justify-content: flex-end;
  }

  &--border + &--border {
    border-top: .1rem solid $cl-grey;
  }

  @include xs('+') {
    padding: 0;
  }

  > * {
    flex-shrink: 0;
    width: 100%;
    padding-left: math.div($gutter, 4);
    padding-right: math.div($gutter, 4);

    @include xs('+') {
      padding-left: math.div($gutter, 2);
      padding-right: math.div($gutter, 2);
    }
  }
}

.group--centered {
  justify-content: center;
}

.group--vertical-centered {
  align-items: center;
}

.group--align-right {
  justify-content: flex-end;
}

.container {
  width: 100%;
  max-width: $max-container-width;
  margin: 0 auto;
  padding: 0 math.div($gutter , 2);
}

.container--narrow {
  max-width: 76.8rem;
}

.container-fluid {
  width: 100%;
  float: left;
  clear: both;
  padding: 0 8rem;
}


////////////////////////
//  Grid loop for creating classes like '.xs12' and '.md6'
////////////////////////

@each $device, $description, $min-width, $max-width in $devices {

  @media screen and (min-width: $min-width) {

    body:after {
      content: "#{$description}";
    }

    @for $column from 0 through $column-count {

      @if $grid-variant == 'placehold' {
        %#{$device}#{$column} {
          min-height: 1px;
          padding-left: math.div($gutter , 2);
          padding-right: math.div($gutter , 2);
          position: relative;
          width: ($column * $column-percentage-width);
        }
      } @else {
        .#{$device}#{$column} {
          min-height: 1px;
          position: relative;
          width: ($column * $column-percentage-width);
          padding-left: math.div($gutter , 2);
          padding-right: math.div($gutter , 2);
        }
      }
    }

    @for $column from 0 through $column-count {
      [data-push-left~="#{$device}#{$column}"] {
        margin-left: ($column * $column-percentage-width);
      }
    }
  }
}
