@use 'sass:math';

.__react_component_tooltip {
  padding: math.div($spacing, 2) !important;
  font-size: 1.4rem !important;
  font-weight: $font-weight--bold;
  line-height: 1 !important;
  background-color: $cl-grey-darker !important;

  &:after {
    border-top-color: $cl-grey-darker !important;
  }
}
