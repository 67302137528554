footer {
  max-width: $max-container-width;
  margin: 4.8rem auto 4.8rem;

  p {
    text-align: center;
    font-size: 1.4rem;
    color: $cl-grey-dark;
    line-height: 1.43;

    + p {
      margin-top: 2.4rem;
    }
  }

  a {
    color: $cl-grey-dark;
    font-weight: $font-weight--bold;
  }
}
