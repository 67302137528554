.pagination {
  position: fixed;
  z-index: 2;
  left: 50%;
  bottom: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: .8rem;
  padding: .8rem;
  background-color: $cl-white;
  box-shadow: 0 .2rem .8rem rgba($cl-black, .05), 0 0 0 .1rem rgba($cl-black, .07);
  transform: translateX(-50%);

  > * + * {
    margin-left: .8rem;
  }
}

.pagination-overlay {
  z-index: 1;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10rem;
  background: linear-gradient(
    to bottom,
    hsla(0, 0%, 0%, 0) 0%,
    hsla(0, 0%, 0%, 0.014) 11.8%,
    hsla(0, 0%, 0%, 0.028) 22.6%,
    hsla(0, 0%, 0%, 0.042) 32.4%,
    hsla(0, 0%, 0%, 0.057) 41.2%,
    hsla(0, 0%, 0%, 0.073) 49.3%,
    hsla(0, 0%, 0%, 0.088) 56.5%,
    hsla(0, 0%, 0%, 0.105) 63.1%,
    hsla(0, 0%, 0%, 0.121) 69%,
    hsla(0, 0%, 0%, 0.138) 74.4%,
    hsla(0, 0%, 0%, 0.156) 79.4%,
    hsla(0, 0%, 0%, 0.174) 84%,
    hsla(0, 0%, 0%, 0.192) 88.3%,
    hsla(0, 0%, 0%, 0.211) 92.3%,
    hsla(0, 0%, 0%, 0.23) 96.2%,
    hsla(0, 0%, 0%, 0.25) 100%
  );
  pointer-events: none;
  transition: transform 500ms, opacity 500ms;
}

.pagination-overlay--at-bottom {
  transform: translateY(50%);
  opacity: 0;
}

.pagination-spacer {
  margin-bottom: 5.6rem + $gutter;
}

.pagination__link {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  font-weight: $font-weight--bold;
  user-select: none;

  &:hover {
    background-color: $cl-grey-light;
  }

  &.is-active {
    background-color: $cl-primary-light;
    color: $cl-white;
    transition: none;
  }

  &.is-disabled {
    opacity: .25;
    pointer-events: none;
  }
}
