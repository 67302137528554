.chip {
  display: inline-block;
  user-select: none;
  padding: .8rem;
  font-size: 1.4rem;
  font-weight: $font-weight--bold;
  line-height: 1;
  text-align: center;
  border-radius: .4rem;
}

.chip--valid {
  color: $cl-secondary;
  background-color: $cl-secondary-lightest
}

.chip--warning {
  color: $cl-warning;
  background-color: $cl-warning-lightest;
}

.chip--danger {
  color: $cl-danger;
  background-color: $cl-danger-lightest
}

.chip--disabled {
  color: $cl-grey-dark;
  background-color: $cl-disabled;
}

.chip--default {
  color: $cl-default;
  background-color: $cl-default-lightest;
}
