@use 'sass:math';

$input-shadow: 0 .2rem .4rem rgba($cl-black, .02), 0 0 0 .1rem $cl-grey-alt inset, .1rem .2rem .2rem rgba($cl-black, .04) inset;
$input-shadow--hover: 0 .2rem .4rem rgba($cl-black, .02), 0 0 0 .1rem $cl-grey-dark inset, .1rem .2rem .2rem rgba($cl-black, .04) inset;
$input-shadow--disabled: 0 .2rem .4rem rgba($cl-black, .02), 0 0 0 .1rem $cl-grey-alt inset, .1rem .2rem .2rem rgba($cl-black, .04) inset;
$input-shadow--error: 0 0 .3rem rgba($cl-danger, .3), 0 0 0 .1rem $cl-danger inset, .1rem .2rem .2rem rgba($cl-danger, .04) inset;

.input,
.select {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &:hover,
  &:focus-within {

    .input__container,
    .input__append,
    .input__prepend,
    .react-select,
    .select__container {
      box-shadow: $input-shadow--hover;
    }
  }

  &.has-error.is-required,
  &.is-dirty.is-invalid {

    .input__container,
    .input__prepend,
    .input__append,
    .react-select {
      box-shadow: $input-shadow--error;
    }

    .input__prepend,
    .input__append {
      color: $cl-danger;
    }
  }

  &.is-disabled {
    pointer-events: none;

    .input__element {
      background-color: unset;
    }

    .input__container {
      background-color: $cl-grey-lighter;
    }

    .input__container,
    .input__prepend,
    .input__append,
    .react-select {
      box-shadow: $input-shadow--disabled;
    }

    .input__append {
      color: $cl-grey-darker;
      background-color: $cl-grey-lighter;
    }
  }
}

.input--text,
.input--date,
.input--email,
.input--search,
.input--tel,
.input--number,
.input--password {
  @extend %depth--content;
  position: relative;

  .input__element {
    flex-grow: 1;
    min-width: 0;
    height: $default-height;
    padding: 1rem $spacing;
    -moz-appearance: textfield;

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    .header & {
      color: $cl-white;
      background-color: rgba($cl-white, .1);
    }
  }
}

.input--date {
  z-index: unset;
  position: unset;
}

.input--search {
  position: relative;

  &:before {
    @extend %depth--input-helper;
    content: "";
    position: absolute;
    top: 50%;
    left: $spacing;
    width: 1.6rem;
    height: 1.6rem;
    mask-image: url("../../../images/icons/search.svg");
    transform: translate3d(0, -50%, 0);
    background-color: $cl-black;

    .header & {
      background-color: $cl-white;
    }
  }

  .input__element {
    padding-left: 4.8rem;
  }
}

.input__prepend,
.input__append {
  @extend %depth--input-helper;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: $default-height;
  height: $default-height;
  padding: 0 $spacing;
  font-size: 1.5rem;
  background-color: $cl-white;
  box-shadow: $input-shadow;
  white-space: nowrap;
}

.input__container,
.select__container {
  position: relative;
  width: 100%;
  display: flex;
  background-color: $cl-white;
  border-radius: $border-radius;
  box-shadow: $input-shadow;
}

.input__prepend,
.input__append,
.input__container,
.select__container,
.react-select,
.textarea__element {
  transition: {
    property: color, background-color, box-shadow, border-color;
    duration: $transition-duration;
  };
}

.input__prepend {
  margin-right: -.4rem;
  border-radius: $border-radius 0 0 $border-radius;
}

.input__append {
  margin-left: -.4rem;
  border-radius: 0 $border-radius $border-radius 0;
}

.input__top {
  display: flex;
  align-items: center;
  width: 100%;
  height: 2rem;
  margin-bottom: 1.6rem;
}

.input__aside {
  margin-left: auto;

  .chip {
    font-size: 1.4rem;
    padding: .4rem .8rem;
  }
}

a.input__aside {
  text-decoration: underline;
}

.input__element,
.react-select {
  width: 100%;
  border-radius: $border-radius;
  background: none;
  border: none;
  box-shadow: none;
  outline: none;

  &:disabled {
    color: $cl-grey-dark;
    background-color: $cl-grey-lighter;
  }
}

.input__label {
  position: relative;
  font-weight: $font-weight--bold;
  line-height: 1.25;

  span {
    color: $cl-danger;
  }
}

.input__error-label {
  display: flex;
  align-items: center;
  padding-top: math.div($spacing, 2);
  color: $cl-danger;
  font-size: 1.4rem;
  line-height: 1;
  font-weight: $font-weight--bold;

  svg {
    margin-right: math.div($spacing, 2);
  }
}

.suggestions-item {
  appearance: none;
  display: block;
  width: 100%;
  padding: 1.4rem 1.6rem;
  text-align: left;
  line-height: 1.25;

  &:hover,
  &:focus {
    background-color: $cl-primary;
    color: $cl-white;
  }
}

.suggestions-item__context {
  margin-left: .8rem;
  opacity: .5;
}
