@use 'sass:math';

.quote-options-list {
  list-style: none;
  margin: 0;

  &:not(:last-child) {
    margin-bottom: 3.2rem;
  }

  .select {
    width: 100%;
  }

  &:empty + .button {
    color: $cl-danger;
    box-shadow: 0 .1rem .4rem rgba(10, 17, 26, .04), 0 0 0 .1rem rgba($cl-danger, .5) inset;

    svg path {
      stroke: $cl-danger;
      fill: $cl-danger;
    }
  }
}

.quote-options-list__item {
  h5 {
    font-weight: $font-weight--bold;
    margin-bottom: 1.6rem;
  }

  + .quote-options-list__item {
    padding-top: 1.6rem;
  }

  &--has-border {
    &:not(:last-child) {
    }

    + .quote-options-list__item {
      border-top: 1px solid $cl-input-border;
      padding-top: 3.2rem;
    }

    + .quote-options-list__item--thin {
      margin-top: 1.6rem;
      margin-bottom: 1.6rem;
      padding-top: 3.2rem;
    }
  }

  .quote-options-list__item--thin {
    padding-top: 1.6rem;
    padding-bottom: 0;
  }
}

.quote-options-list__item.bid-status {
  &--denied {
    .react-select__single-value {
      color: $cl-danger;
    }
  }
}

.quote-options-list__actions {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;

  .button {
    margin-left: math.div($spacing, 2);
  }
}

.quote-options-list__error {
  margin-top: .8rem;
}

.quote-options-list__error-icon {
  margin: .2rem .8rem 0 0;
  vertical-align: top;
}

.quote-options-list__error-message {
  line-height: 1.43;
  color: $cl-danger;
  font-weight: $font-weight--bold;
}
