@use 'sass:math';

.react-datepicker-wrapper {
  @extend .input;
  @extend .input--text;
  width: 100%;
}

.react-datepicker__input-container {
  @extend .input__container;
  width: 100%;

  &:after {
    pointer-events: none;
    position: absolute;
    right: $spacing;
    top: $spacing;
    content: "";
    width: 1.6rem;
    height: 1.6rem;
    background: url("../../images/icons/calendar.svg");
  }

  input {
    @extend .input__element;
    width: 100%;
  }
}

.react-datepicker__navigation {
  position: absolute;
  overflow: hidden;
  display: flex;
  align-items: center;
  width: 7.6rem;
  height: 7.6rem;
  padding: $spacing;
  text-indent: -99rem;
  background: {
    repeat: no-repeat;
    position: center;
  }
}

.react-datepicker__navigation--previous {
  justify-content: flex-start;
  left: 0;
  background-image: url("../../images/icons/chevron-left.svg");
}

.react-datepicker__navigation--next {
  justify-content: flex-end;
  right: 0;
  background-image: url("../../images/icons/chevron-right.svg");
}

.react-datepicker-popper {
  @extend %depth--dropdown;
  background-color: $cl-white;
  border-radius: $border-radius;
  box-shadow: 0 .1rem .2rem rgba($cl-black, .04), 0 0 0 .1rem $cl-grey-alt;
}

.react-datepicker__day-names {
  display: flex;
  width: 100%;
  padding: 0 $spacing;
  box-shadow: 0 .1rem 0 $cl-grey-alt inset, 0 -.1rem 0 $cl-grey-alt inset;
}

.react-datepicker__day-name {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 100%;
  width: 5rem;
  height: 5rem;
  color: $cl-grey-dark;
  font-weight: $font-weight--bold;
}

.react-datepicker__current-month {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 7.6rem;
  padding: $spacing;
  font-size: 2rem;
  font-weight: $font-weight--bold;
}

.react-datepicker__month {
  display: flex;
  flex-direction: column;
  padding: math.div($spacing, 2) $spacing;
}

.react-datepicker__week {
  display: flex;

  .react-datepicker__day--in-range:first-child {

    &:after {
      border-radius: 5rem 0 0 5rem !important;
    }

    &.react-datepicker__day--selected:after,
    &.react-datepicker__day--range-start:after {
      border-radius: 50% !important;
    }
  }

  .react-datepicker__day--in-range:last-child {

    &:after {
      border-radius: 0 5rem 5rem 0 !important;
    }

    &.react-datepicker__day--selected:after,
    &.react-datepicker__day--range-start:after,
    &.react-datepicker__day--range-end:after {
      border-radius: 50% !important;
    }
  }

  .react-datepicker__day--in-range:first-child.react-datepicker__day--selected.react-datepicker__day--range-end,
  .react-datepicker__day--range-start.react-datepicker__day--in-range:last-child {

    &:before {
      display: none;
    }
  }

  .react-datepicker__day--in-range:last-child.react-datepicker__day--selected:not(.react-datepicker__day--range-end) {

    &:before {
      display: none;
    }
  }
}

.react-datepicker__day {
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 100%;
  width: 5rem;
  height: 5rem;
  font-weight: $font-weight--bold;
  text-align: center;
  outline: none;
  border-radius: 50%;
  transition: color $transition-duration;
}

.react-datepicker__day--outside-month {
  color: $cl-grey-alt;
}

.react-datepicker__day--disabled {
  cursor: default;
  color: $cl-grey-alt;
}

.react-datepicker__day--in-range:not(.react-datepicker__day--range-start):not(.react-datepicker__day--range-end) {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    width: 5rem;
    height: 4rem;
    border-radius: 50%;
    background-color: rgba($cl-primary-light, .25);
    border-radius: 0;
  }
}

.react-datepicker__day--selected,
.react-datepicker__day--range-start,
.react-datepicker__day--range-end {
  color: $cl-white;

  &:after {
    z-index: -1;
    content: "";
    position: absolute;
    left: .5rem;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background-color: $cl-primary;
    animation: scaleIn $transition-duration both;
  }
}

.react-datepicker__day--selected {
  position: relative;
}

.react-datepicker__day--range-start.react-datepicker__day--range-end {

  &:before {
    display: none;
  }
}

.react-datepicker__day--range-start,
.react-datepicker__day--range-end {
  position: relative;

  &:before {
    z-index: -1;
    content: "";
    position: absolute;
    width: 4rem;
    height: 4rem;
    background-color: rgba($cl-primary-light, .25);
  }
}

.react-datepicker__day--range-start {

  &:before {
    left: 1rem;
    border-radius: 5rem 0 0 5rem;
  }
}

.react-datepicker__day--range-end {

  &:before {
    right: 1rem;
    border-radius: 0 5rem 5rem 0;
  }
}
