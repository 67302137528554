@keyframes syncSpinner {
  0% {
    opacity: 1;
    transform: rotate(0deg);
  } 80% {
    opacity: 1;
  } 100% {
    opacity: 0;
    transform: rotate(-1000deg);
  }
}

@keyframes inlineSpinnerAnimation {
  100% {
    transform: rotate(-180deg);
  }
}

.spinner--sync {
  opacity: .5;
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 0 1rem;

  .spinner__element {
    animation: syncSpinner 4000ms 1 forwards;
  }
}

.spinner--inline {
  opacity: .5;
  display: flex;
  align-items: center;
  margin-left: $spacing;
  transition: opacity 300ms;

  &.is-hidden {
    display: none;
  }

  .spinner__element {
    animation: inlineSpinnerAnimation 500ms infinite linear;
  }
}
