.prefer-rating {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 4rem;
  height: 4rem;
  color: $cl-tertiary;
  font-size: 2.4rem;
  font-weight: $font-weight--bold;
  text-align: center;
  background: url("../../images/icons/stamp.svg");
  border-radius: 50%;
  transition: color $transition-duration;

  .header__heading & {
    align-self: flex-start;
    margin-top: .3em;
  }
}
