.detail {
  padding: $gutter 0 0;
}

.detail__top {
  display: flex;
  align-items: center;
  margin: 0 0 3.6rem;

  .button:first-of-type {
    margin-left: auto;
  }

  > .group {
    flex-grow: 1;
  }
}

.detail__top-heading {
  display: flex;
  align-items: center;
}

.detail__top-actions {
  margin-left: auto;
}
