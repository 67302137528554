.results__meta {
  display: flex;
  align-items: center;
  padding-bottom: $spacing;
}

.results__list {
  @include list-reset;

  .result:last-child {
    box-shadow: none;
  }
}

.result {
  position: relative;
  display: flex;
  align-items: center;
  padding: 2.4rem 0;
  box-shadow: 0 .1rem 0 0 $cl-grey-light;

  &.is-inactive {
    filter: grayscale(100%);

    .result__heading--alt {
      color: $cl-grey-darker;
    }
  }

  > .button {
    margin-left: auto;
  }

  &.result--suppliers:first-child {
    padding-top: 6.4rem;

    .result__relationship {
      top: 6.4rem
    }

    .result__heading {
      &::before {
        content: 'Supplier name, location & info';
        position: absolute;
        top: 1.6rem;
        font-size: 1.6rem;
        pointer-events: none;
        font-weight: normal;
        color: $cl-grey-dark;
      }
    }

    .result__types {
      &::before {
        position: absolute;
        top: 1.6rem;
        content: 'Type';
        pointer-events: none;
        color: $cl-grey-dark;
      }
    }
  }
}

.result--card {
  @extend .card;
  @extend .card--primary;
  align-items: flex-start;
  padding: $gutter;
  flex-direction: column;
  box-shadow: 0 .2rem .8rem rgba($cl-black, .05), 0 0 0 .1rem $cl-grey-light;

  .result__headings {
    position: relative;
  }

  .result__relationship {
    top: .2rem;
    cursor: default;

    + .result__heading {
      margin-left: 4rem;
    }
  }

  .result__top {
    display: flex;
    align-items: center;
    width: 100%;

    button + * {
      margin-left: 1.6rem;
    }

    .card-headings {
      margin-bottom: 0;
    }
  }

  .result__orders {
    width: 100%;
    margin: $gutter 0 0 0;
    padding-top: $gutter;
    list-style: none;
    box-shadow: 0 .1rem 0 0 $cl-grey inset;
  }

  .result__orders-title {
    margin-bottom: 2.4rem;

    h5 {
      font-weight: $font-weight--bold;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .result__orders-item {
    display: flex;
    width: 100%;
    margin-bottom: $gutter;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .result__orders-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-basis: 100%;
    line-height: 1.25;

    &:last-child {
      align-items: flex-end;
    }

    strong {
      margin-bottom: .4rem;
    }
  }
}

.result--suppliers {
  padding-left: $spacing + 2.4rem;
}

.result-labels {
  display: flex;
  transform: translate3d(calc(#{-$gutter} - 1px), calc(#{-$gutter} - 1px), 0);

  .result-labels__item {
    margin-right: .2rem;
    padding: .4rem $spacing;
    color: $cl-white;
    font-size: 1.4rem;
    font-weight: $font-weight--bold;
    background-color: $cl-secondary-light;
    border-radius: 0 0 .4rem .4rem;

    &:first-child {
      border-radius: .8rem 0 .4rem 0;
    }

    &.default {
      background-color: $cl-default;
    }
  }
}

.result__content {
  align-self: flex-start;
}

.result__anchor {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
}

.result__relationship {
  position: absolute;
  top: 2.4rem;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
  font-weight: $font-weight--bold;
  color: $cl-white;
  margin-right: $spacing;
  mask-image: url("../../images/icons/stamp.svg");
  mask-size: 2.4rem;
  background-color: $cl-primary;

  &--inactive {
    background-color: $cl-grey-darker;
  }
}

.result__heading {
  @extend %h4;
  color: $cl-primary;
  font-weight: $font-weight--bold;

  > .result__subtitle {
    margin-top: .8rem;
    font-weight: normal;
  }

  &--inactive {
    color: $cl-grey-darker;

    &::after {
      content: ' (inactive)';
    }
  }
}

.result__subheading {
  display: flex;
  color: $cl-grey-darkest;
  font-size: 1.6rem;

  .rating {
    margin-left: $spacing;
  }

  .rating__stars {
    background-color: $cl-grey-alt;
  }

  .rating__number {
    color: $cl-grey-dark;
  }
}

.result__subtitle {
  font-size: 1.6rem;
  color: $cl-grey-darker;
}

.result__subtitle--warning {
  font-size: 1.6rem;
  padding-left: 2.4rem;
  background-image: url('../../images/icons/warning.svg');
  background-repeat: no-repeat;
  background-position: 0 .5rem;
  color: $cl-grey-darker;
}

.result__heading--alt {
  @extend %h5;
  color: $cl-grey-darkest;
  line-height: 1.4;

  .chip {
    margin-left: .8rem;
  }
}

.result__subheading--alt {
  color: $cl-grey-dark;
  margin-top: .4rem;
}

.result__aside {
  display: flex;
  margin-left: auto;

  .card-meta {
    margin-right: 3.2rem;

    .compared-value {
      line-height: 1.25;
    }
  }

  .rating {
    color: $cl-grey-dark;
    margin-left: auto;
  }

  .rating__stars {
    background-color: $cl-grey;
  }

  .button {
    align-self: center;

    > * {
      pointer-events: none;
    }

    ~ .button {
      margin-left: 1.6rem;
    }
  }

  .result__types {
    display: flex;
    flex-direction: column;
    width: 10rem;
    text-transform: capitalize;
    margin: 0 2.4rem;

    span {
      color: $cl-black;
      font-size: 1.4rem;
    }
  }
}

.compared-value {
  &--above-average {
    color: $cl-danger;
  }

  &--below-average {
    color: $cl-valid;
  }

  &--average {
    color: $cl-valid;
  }
}
