@use 'sass:math';

.dropdown-button {
  position: relative;
  float: left;
  max-width: 100%;
  width: 100%;
}

.dropdown-button--secondary {
  display: flex;
  width: auto;
  height: 4rem;
  border-radius: $border-radius;
  box-shadow: 0 .1rem .4rem rgba(10, 17, 26, .04), 0 0 0 .1rem rgba($cl-black, .1) inset, .1rem 0 0 0 rgba($cl-white, .1);
  transition: {
    property: color, background-color;
    duration: $transition-duration;
  }

  .dropdown-button__action {
    padding: 0 1.2rem;
    font-weight: $font-weight--bold;
    border-radius: $border-radius 0 0 $border-radius;
    box-shadow: 0 .1rem .2rem rgba($cl-black, 0), 0 0 0 .1rem rgba($cl-black, 0) inset;
    transition: {
      property: color, background-color;
      duration: $transition-duration;
    }

    &:hover,
    &:focus {
      box-shadow: 0 .1rem .2rem rgba($cl-black, .04), 0 0 0 .1rem rgba($cl-black, .5) inset;
    }
  }

  .dropdown-button__toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 4rem;
    min-height: 4rem;
    padding: 0;
    border-radius: 0 $border-radius $border-radius 0;

    .dropdown-button__icon {
      margin: 0;
    }
  }
  .dropdown-button__options {
    min-width: 100%;
    padding: 0;

    > .button {
      width: 100%;
      font-weight: $font-weight--medium;
      border-radius: 0;
      box-shadow: none;
    }
  }
}

.dropdown-button__toggle {
  @extend .button;
  @extend .button--ghost;
  font-weight: $font-weight--medium;
  background-color: $cl-white;
  max-width: 100%;
  width: 100%;
  transition: none;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: $spacing;
  }

  &:hover,
  &:focus {
    box-shadow: 0 .1rem .2rem rgba($cl-black, .04), 0 0 0 .1rem rgba($cl-black, .5) inset;
  }
}

.dropdown-button__icon {
  margin-left: auto;
  flex-shrink: 0;

  path {
    fill: $cl-black;
  }
}

.dropdown-button__options {
  @extend %depth--dropdown;
  visibility: hidden;
  position: absolute;
  top: calc(100% + #{math.div($spacing, 2)});
  right: 0;
  padding: .4rem;
  background-color: $cl-white;
  border-radius: .4rem;
  box-shadow: 0 .1rem .2rem rgba($cl-black, .04), 0 0 0 .1rem $cl-grey-alt inset;

  .dropdown-button.is-active & {
    visibility: visible;
  }

  .checkbox {
    margin: 0;
    white-space: nowrap;
    padding: $spacing $gutter $spacing 1.2rem;

    .checkbox__label {
      line-height: 1;
    }

    &:hover,
    &:focus {
      background-color: $cl-grey-lighter;
      border-radius: .4rem;
    }
  }
}
