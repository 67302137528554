@font-face {
  font-family:"Museo Sans";
  font-weight: $font-weight--medium;
  src:  url("../../fonts/MuseoSans-500/font.woff2") format("woff2"),
        url("../../fonts/MuseoSans-500/font.woff") format("woff");
}

@font-face {
  font-family:"Museo Sans";
  font-weight: $font-weight--medium;
  font-style: italic;
  src:  url("../../fonts/MuseoSans-500Italic/font.woff2") format("woff2"),
  url("../../fonts/MuseoSans-500Italic/font.woff") format("woff");
}

@font-face {
  font-family:"Museo Sans";
  font-weight: $font-weight--bold;
  src:  url("../../fonts/MuseoSans-700/font.woff2") format("woff2"),
        url("../../fonts/MuseoSans-700/font.woff") format("woff");
}

@font-face {
  font-family:"Museo Sans";
  font-weight: $font-weight--bold;
  font-style: italic;
  src:  url("../../fonts/MuseoSans-700Italic/font.woff2") format("woff2"),
  url("../../fonts/MuseoSans-700Italic/font.woff") format("woff");
}

@font-face {
  font-family:"Museo Sans";
  font-weight: $font-weight--black;
  src:  url("../../fonts/MuseoSans-900/font.woff2") format("woff2"),
        url("../../fonts/MuseoSans-900/font.woff") format("woff");
}

@font-face {
  font-family:"Museo Sans";
  font-weight: $font-weight--black;
  font-style: italic;
  src:  url("../../fonts/MuseoSans-900Italic/font.woff2") format("woff2"),
  url("../../fonts/MuseoSans-900Italic/font.woff") format("woff");
}

%font-primary {
  font-family: "Museo Sans", sans-serif;
}

body {
  @extend %font-primary;
  color: $cl-quaternary;
  font-weight: $font-weight--medium;
  line-height: $line-height;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

///////////////////////
//  Headings
///////////////////////

h1, %h1,
h2, %h2,
h3, %h3,
h4, %h4,
h5, %h5,
h6, %h6 {
  margin: 0;
  color: $cl-black;
}

h1, %h1,
h2, %h2,
h3, %h3,
h4, %h4 {
  font-weight: $font-weight--bold;
}

h5, %h5,
h6, %h6 {
  font-weight: $font-weight--medium;
}

h1,
%h1 {
  @extend %font-primary;
  @include font-size(5.2rem, 6.4rem);
  line-height: 1.125; // 7.2rem

  a {
    color: inherit;
    text-decoration: underline;
  }
}

h2,
%h2 {
  @extend %font-primary;
  @include font-size(4rem, 4.8rem);
  letter-spacing: .1rem;
  line-height: 1.167; // 5.6rem

  a {
    color: inherit;
    text-decoration: underline;
  }
}

h3,
%h3 {
  @extend %font-primary;
  @include font-size(2.6rem, 3.2rem);
  line-height: 1.125; // 3.6rem
}

h4,
%h4 {
  @extend %font-primary;
  @include font-size(2rem, 2.4rem);
  line-height: 1.167; // 2.8rem

  a {
    color: inherit;
    text-decoration: underline;
  }
}

h5,
%h5 {
  @extend %font-primary;
  @include font-size(1.8rem, 2rem);
  line-height: 1.333; // 3.2rem

  a {
    color: inherit;
    text-decoration: underline;
  }
}

h6,
%h6 {
  @extend %font-primary;
  @include font-size(1.4rem, 1.6rem);
  line-height: 1.4; // 2.8rem

  a {
    color: inherit;
    text-decoration: underline;
  }
}

.heading--primary {
  @include font-size(3.2rem, 4rem, 6rem);
  color: $cl-white;

  span {
    color: $cl-primary;
  }
}

.subtitle {
  @include font-size(2rem);
  line-height: 1.6; // 3.2rem
}

hr {
  border: none;
  border-top: .1rem solid $cl-disabled;
}

///////////////////////
//  Global styling
///////////////////////

// ::selection {
//   color: $cl-white;
//   background: rgba($cl-primary, .5);
// }

a {
  color: $cl-primary;
  text-decoration: none;
  transition: color 200ms;
  cursor: pointer;

  &:hover {
    color: $cl-primary;
  }
}

///////////////////////
//  Code typography
///////////////////////

pre,
code,
samp,
style {
  font-family: $mono-font;
}

pre {
  @include font-size(1.4rem);
  overflow: hidden;
  width: 100%;
  color: $cl-white;
  padding: $spacing * 2;
  margin: 3rem 0;
  background-color: $cl-black;

  code {
    display: block;
    padding: 0;
    color: inherit;
    border-radius: 0;
    background-color: inherit;
  }
}

code {
  @include font-size(1.4rem);
  color: $cl-white;
  background-color: $cl-black;
  border-radius: $border-radius;
  padding: .15em .4em;
}

p {
  a {
    color: $cl-primary;
    text-decoration: underline;
  }
}

ol {
  padding-left: 3.2rem;
  margin-bottom: 2rem;

  + h4 {
    margin-top: 3rem;
  }
}

strong {
  font-weight: $font-weight--bold;
}
