@use 'sass:math';

.account-dropdown {
  @extend %depth--dropdown;
  position: relative;
  margin-left: $spacing;

  &.is-visible {

    .account-dropdown__list {
      visibility: visible;
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
}

.account-dropdown__toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 5rem;
}

.account-dropdown__list {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: calc(100% + #{math.div($spacing, 2)});
  right: 0;
  display: flex;
  flex-direction: column;
  min-width: 20rem;
  margin: 0;
  padding: math.div($spacing, 2);
  list-style: none;
  border-radius: $border-radius;
  background-color: $cl-white;
  transform: translate3d(0, -1rem, 0) scale(.98);
  box-shadow: 0 .2rem .8rem rgba($cl-black, .05), 0 0 0 .1rem $cl-grey-light;
  transition: {
    property: visibility, opacity, transform;
    duration: $transition-duration;
  }
}

.account-dropdown__button {
  width: 100%;
  padding: math.div($spacing, 2) $spacing;
  color: $cl-black;
  text-align: left;
  white-space: nowrap;
  border-radius: $border-radius;
  transition: {
    property: color, background-color;
    duration: $transition-duration;
  }

  &:hover,
  &:focus {
    background-color: $cl-grey-lighter;
  }

  &.is-disabled {
    opacity: .3;
    cursor: default;

    &:hover,
    &:focus {
      color: $cl-black;
    }
  }
}
