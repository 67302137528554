.accentuate-box {
  display: flex;
  align-items: center;
  margin: $spacing 0;
  padding: 2.4rem;
  box-shadow: 0 .1rem .2rem rgba($cl-black, .04), 0 0 0 .1rem $cl-grey-alt inset;
  border-radius: $border-radius;

  &:last-child {
    margin-bottom: 0;
  }
}

.accentuate-box__left {
  margin-right: $gutter;
}

.accentuate-box__right {
  margin-left: auto;
}

.accentuate-box__heading {
  font-size: 1.6rem;
  font-weight: $font-weight--bold;
}

.accentuate-box__content {
  color: $cl-grey-darker;
  font-size: 1.4rem;
}
