@use 'sass:math';

.list {
  overflow: hidden;
  max-width: 100%;
}

.list--reset {
  @include list-reset;
}

.list--icons {
  @include list-reset;

  .list__item {
    display: flex;
    margin-bottom: .5rem;
  }

  .list__item-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $spacing;
    margin-right: $spacing;
  }

  .list__item-content {
    overflow: hidden;

    a {
      overflow: hidden;
      display: block;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
      font-weight: $font-weight--bold;
      text-decoration: underline;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }
}

.list--additionals {
  @include list-reset;
  padding-top: .2rem;
  padding-right: .2rem;

  .list__item {
    display: flex;
    margin-bottom: $spacing;

    > div {
      display: flex;
      align-items: center;
    }
  }

  .list__item-column--center {
    display: flex;
    justify-content: center;
  }
}

.list--selected-suppliers {
  @include list-reset;
  margin: $spacing 0 0;
  overflow: visible;

  .list__item {
    display: flex;
    align-items: flex-start;
    margin: $spacing math.div(-$gutter, 2);

    .item-label {
      margin-top: 1.2rem;
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
