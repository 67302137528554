@use 'sass:math';

.card {
  background-color: $cl-white;
  border-radius: .8rem;

  > .group {
    margin-bottom: 2.4rem;

    &--small-margin {
      margin-bottom: 1.6rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul:not(:last-child) {
    margin-bottom: 1.6rem;
    line-height: 1.4;
  }

  li + li {
    margin-top: .8rem;
  }
}

.card--primary {
  margin-bottom: $spacing;
  padding: $gutter;
  box-shadow: 0 .2rem .8rem rgba($cl-black, .05), 0 0 0 .1rem $cl-grey-light;

  .group > .xxs12 > .card__column:last-child {
    margin-bottom: 0;
  }
}

.card--overview {
  min-height: $gutter * 4;
  margin: -$gutter * 2 0 $spacing;
  padding: $gutter;
  box-shadow: 0 .2rem .8rem rgba($cl-black, .05), 0 0 0 .1rem $cl-grey-light;

  .card__top {
    display: flex;
    align-items: center;
    box-shadow: none;

    .card__heading {
      margin: 0;
    }

    .card__actions {
      margin-left: auto;

      .select {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: nowrap;
      }
      .select__label {
        margin: 0 $spacing 0 0;
      }
    }
  }
}

.card--overview + .card--overview {
  margin-top: 0;
}

.card--vertical-split {
  .group > .md6 {
    + .md6 {
      border-left: .1rem solid $cl-grey-light;
    }
  }

  .input + .input {
    margin-top: 2.4rem;
  }
}

.card__top {
  display: flex;
  margin-bottom: $spacing;
  box-shadow: 0 .1rem 0 0 $cl-grey-light;

  .card-headings {
    margin-bottom: 0;
  }
}

.card__top--secondary {
  box-shadow: none;
}

.card-headings {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: $gutter;

  h4,
  h5 {
    margin: 0;
  }

  h4 {
    font-weight: $font-weight--bold;

    + h5 {
      margin-top: .4rem;
    }
  }

  h5 {
    color: $cl-grey-darker;
  }
}

.card__heading {
  margin-bottom: 2.4rem;
}

.card__subheading {
  font-weight: $font-weight--medium;
}

.card__row-spacer {
  height: 1.6rem;
}

.card__column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: $spacing;

  .chip {
    font-size: 1.6rem;
  }

  > .input {
    width: 100%;
  }
}

.card__column-subheading {
  margin-bottom: math.div($spacing, 2);
  font-weight: $font-weight--bold;
}

.card-actions {
  margin-left: $gutter;
}

.card-meta {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: $gutter;
  justify-content: center;
}

.card-meta__price {
  font-weight: $font-weight--bold;
}

.card-meta__average {
  font-weight: $font-weight--bold;
}

.card-meta__average--below {
  color: $cl-valid;
}

.card-meta__average--above {
  color: $cl-danger;
}

.card-list {
  @include list-reset;
  display: flex;
  flex-direction: column;
  margin-top: $gutter;

  .card-list__item:last-of-type {
    margin-bottom: 0;
  }
}

.card-list__item {
  display: flex;
  width: 100%;
  margin-bottom: $gutter;

  &.group {
    width: calc(100% + #{$gutter});
  }
}

.card-list__column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  line-height: 1.25;

  &--align-right {
    align-items: flex-end;
  }
}
