.badge {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 2rem;
  height: 2rem;
  font-size: 1.2rem;
  border-radius: 50%;
}

.badge--primary {
  color: $cl-white;
  background-color: $cl-primary;
}
