@keyframes slideDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  } to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideUp {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  } to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes slideRight {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  } to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideLeft {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  } to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}

.react-notification-root {
  position: relative;

  .notification-container-top-center {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;

    .notification-parent {
      transition: height 400ms !important;
    }
  }
}
