.navigation--primary {
  height: 100%;

  .navigation__list,
  .navigation__button {
    height: 100%;
  }
}

.navigation--secondary {

  .navigation__button {
    @extend .button;
    color: $cl-white;
    padding: $spacing;
    font-weight: $font-weight--bold;
    border: none;
    box-shadow: none;
    border-radius: $border-radius;

    &:hover,
    &:focus {
      color: $cl-white;
      background-color: rgba($cl-black, .1);
    }

    &.is-active {
      opacity: 1;
      background-color: $cl-tertiary-light;
      box-shadow: none;
    }

    &.is-disabled {
      opacity: .5;
    }
  }
}

.navigation__list {
  @include list-reset;
  display: flex;
}

.navigation__list-item {
  padding: 0 $spacing;
}

.navigation__button {
  @extend .button;
  color: $cl-white;
  padding: $spacing 0;
  font-weight: $font-weight--bold;
  white-space: nowrap;
  border: none;
  border-radius: 0;
  box-shadow: none;

  &:hover,
  &:focus {
    color: $cl-white;
  }

  &.is-active {
    box-shadow: 0 -.4rem 0 0 $cl-white inset;
  }
}

.navigation__button-step {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
  margin-right: $spacing;
  color: $cl-tertiary;
  font-size: 1.4rem;
  background-color: $cl-white;
  border-radius: 50%;
}
