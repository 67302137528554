@use 'sass:math';

$cl-primary:              #00A060;
$cl-primary-light:        #00AD68;
$cl-primary-lighter:      #44B98B;
$cl-primary-lightest:     #EAF7F2;

$cl-secondary:            #58B336;
$cl-secondary-light:      #6BC04B;
$cl-secondary-lighter:    #ABD89A;
$cl-secondary-lightest:   #F0F9E8;

$cl-tertiary-light:       #343941;
$cl-tertiary:             #1F242D;

$cl-quaternary:           #000000;
$cl-quaternary-light:     #000000;

$cl-white:                #ffffff;
$cl-black:                #000000;
$cl-grey:                 #E1E4EB;
$cl-grey-light:           #E8EBF0;
$cl-grey-lighter:         #F7F8FA;
$cl-grey-dark:            #898E9C;
$cl-grey-darker:          #6F7481;
$cl-grey-darkest:         #282D37;
$cl-grey-alt:             #D9DDE6;

$cl-disabled:             #E8EBF0;
$cl-valid:                #58B336;
$cl-danger:               #F23232;
$cl-danger-light:         #F56565;
$cl-danger-lighter:       #F89898;
$cl-danger-lightest:      #FEEBEB;
$cl-warning:              #F2AF32;
$cl-warning-light:        #F5C365;
$cl-warning-lighter:      #F8D798;
$cl-warning-lightest:     #FEF7EB;
$cl-default:              #27A3E9;
$cl-default-light:        #6BBCD6;
$cl-default-lighter:      #9DD2E3;
$cl-default-lightest:     #EBF6F9;

$cl-input-border:         #E1E4EB;

$grid-variant:            'class' !default; // Options: 'class' (default) or 'placehold'

$base-min-width:          0 !default;
$xxs-min-width:           375px !default; // Mobile M
$xs-min-width:            425px !default; // Mobile L
$sm-min-width:            768px !default; // Tablet
$md-min-width:            1024px !default; // Laptop
$lg-min-width:            1440px !default; // Laptop L
$xl-min-width:            2560px !default; // 4K

$base-max-width:          $xxs-min-width - 1 !default;
$xxs-max-width:           $xs-min-width - 1 !default;
$xs-max-width:            $sm-min-width - 1 !default;
$sm-max-width:            $md-min-width - 1 !default;
$md-max-width:            $lg-min-width - 1 !default;
$lg-max-width:            $xl-min-width - 1 !default;
$xl-max-width:            100% !default;

$column-count:            12 !default;
$gutter:                  3.2rem !default;
$max-container-width:     112rem + $gutter !default; // 32px is the gutter
$column-px-width:         math.div($max-container-width, $column-count);
$column-percentage-width: math.div($column-px-width, $max-container-width) * 100%;
$devices:                 ('base', 'base: 0 -> #{$base-max-width}', $base-min-width, $base-max-width),
                          ('xxs', 'xxs: mobile M (+#{$xxs-min-width})', $xxs-min-width, $xxs-max-width),
                          ('xs', 'xs: mobile L (+#{$xs-min-width})', $xs-min-width, $xs-max-width),
                          ('sm', 'sm: tablet (+#{$sm-min-width})', $sm-min-width, $sm-max-width),
                          ('md', 'md: laptop (+#{$md-min-width})', $md-min-width, $md-max-width),
                          ('lg', 'lg: desktop (+#{$lg-min-width})', $lg-min-width, $lg-max-width),
                          ('xl', 'xl: 4K (+#{$xl-min-width})', $xl-min-width, $xl-max-width);

$spacing:                 math.div($gutter, 2) !default;
$default-height:          ($spacing * 3) !default;

$border-radius:           .6rem !default;

$mono-font:               'Office Code Pro', Monaco, Consolas, monospace !default;
$font-size:               16px !default;
$line-height:             1.75 !default;

$font-weight--thin:       100;
$font-weight--light:      300;
$font-weight--regular:    400;
$font-weight--medium:     500;
$font-weight--bold:       700;
$font-weight--black:      900;

$cubic-bezier:            cubic-bezier(.4, 0, .2, 1) !default;
$cubic-bezier--in:        cubic-bezier(0, 0, .2, 1) !default;
$cubic-bezier--out:       cubic-bezier(.4, 0, 1, 1) !default;
$transition-duration:     200ms !default;

$box-shadow:              0 0 0 .1rem rgba($cl-black, .1);
