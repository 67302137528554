.card {
  .group.note,
  .group.comment {
    position: relative;
    margin-bottom: 1.6rem;

    .xxs2 {
      text-align: right;
    }

    .ellipsis-button {
      display: inline-block;
      margin-left: auto;
      width: 3.2rem;
      height: 3.2rem;

      .button {
        display: block;
        width: 3.2rem;
        min-height: 3.2rem;
        padding: 0;
      }
    }

    + .note,
    + .comment {
      padding-top: 1.6rem;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 1.6rem;
        width: calc(100% - 3.2rem);
        height: .1rem;
        background-color: $cl-grey;
      }
    }
  }

  .note-container-editing {
    padding: 1.6rem;
    border-left: .4rem solid $cl-warning;
    box-shadow: 0 .1rem .4rem rgba(10, 17, 26, .04), 0 0 0 .1rem rgba($cl-black, .1) inset, .1rem 0 0 0 rgba($cl-white, .1);
    border-radius: .4rem 1rem 1rem .4rem;
    margin: 1.6rem 0;

    .group + .group {
      margin-top: 1.6rem;
    }
  }

  .note__date,
  .comment__date {
    color: $cl-grey-dark;
    margin-bottom: 0;
  }
}
