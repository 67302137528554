.column-table {
  border-collapse: collapse;

  th {
    label {
      display: block;
      padding-bottom: .8rem;
      line-height: 1.25;
    }
  }

  th,
  td {
    padding: 0;
    height: 0;
    width: 50%;

    + th,
    + td {
      padding-left: 1.6rem;
    }
  }
}

.column-table__list-item {
  td {
    padding: 1.6rem 0 0;

    span {
      display: block;
      margin: 0;
    }

    &:last-child {
      vertical-align: middle;

      button {
        margin-left: auto;
      }
    }
  }

  &:nth-child(2) {
    td {
      padding-top: 3.2rem;
    }
  }
}
