@use 'sass:math';

.additionals-list {
  position: relative;

  &.is-expanded {

    .list {
      max-height: 1000rem;
      transition: {
        duration: 600ms;
        timing-function: ease-in-out;
      }
    }
  }

  .list {
    overflow: hidden;
    max-height: 26rem;
    transition: max-height 300ms cubic-bezier(0, 1, 0, 1);

    &:not(:last-child) {
      padding-bottom: $gutter * 2;
    }
  }
}

.additionals-list__header {
  display: flex;
  margin-bottom: $spacing;
}

.additionals-list__column {
  display: flex;
  width: 20%;

  &:nth-child(2) {
    width: calc(20% + 3.2rem);

    > .input {
      margin-right: 3.2rem;
    }
  }
}

.additionals-list__column--center {
  align-items: center;
  justify-content: center;

  .checkbox {
    margin: 0;
  }
}

.additionals-list__column-label {
  display: flex;
  width: 60%;

  > .input {
    width: 40rem;
    margin-right: $spacing;
  }
}

.additionals-list__show-all {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: $cl-white;

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 100%;
    height: .1rem;
    background-color: $cl-grey;
  }

  > button {
    @extend %depth--button;
    display: flex;
    align-items: center;
    padding: math.div($spacing, 2) $spacing;
    font-weight: $font-weight--bold;
    line-height: 1;
    background-color: $cl-white;
    box-shadow: 0 .1rem .4rem rgba(10, 17, 26, .16), 0 0 0 .1rem rgba($cl-black, .1) inset;
    transition: box-shadow $transition-duration;
    border-radius: $border-radius;

    &:hover,
    &:focus {
      box-shadow: 0 .1rem .4rem rgba(10, 17, 26, .16), 0 0 0 .1rem rgba($cl-black, .5) inset;
    }

    &:after {
      content: "";
      width: 1.2rem;
      height: .6rem;
      margin-left: math.div($spacing, 2);
      mask-image: url("../../images/icons/chevron-down.svg");
      background-color: $cl-black;
      transition: transform $transition-duration;

      .additionals-list.is-expanded & {
        transform: rotate(-180deg);
      }
    }
  }
}
