%depth-0 {
  z-index: -1;
}

@for $i from 1 through 10 {
  %depth-#{$i} {
    z-index: $i * 100;
  }
}

%depth--content {
  z-index: 10;
}

%depth--dropdown,
%depth--input-helper,
%depth--button {
  z-index: 20;
}

%depth--header {
  z-index: 30;
}

%depth--modal {
  z-index: 90;
}

%depth--modal-overlay {
  z-index: 100;
}

%depth--modal-box {
  z-index: 110;
}

%depth--modal-header {
  z-index: 120;
}
