@use 'sass:math';

.auth {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  padding: 1.6rem;
}

.auth__box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 42rem;
  margin: auto auto 0 auto;
  padding: $gutter;
  background-color: $cl-white;
  box-shadow: 0 .2rem .8rem rgba($cl-black, .05), 0 0 0 .1rem $cl-grey-light;
  border-radius: .8rem;
  animation: scaleIn 400ms normal forwards;
}

.auth__logo {
  margin-bottom: $gutter;
}

.auth__heading {
  margin-bottom: 2.4rem;
  font-size: 3.2rem;
  text-align: center;
}

.auth__description {
  margin-bottom: $gutter;
  text-align: center;
}

.auth__errors {
  margin-bottom: $spacing;
  color: $cl-danger;
  font-size: 1.4rem;
  font-weight: $font-weight--bold;

  .icon {
    margin-right: math.div($spacing, 2);
    transform: translate3d(0, .2rem, 0);
  }
}

.auth__success {
  margin-bottom: $spacing;
  color: $cl-valid;
  font-size: 1.4rem;
  font-weight: $font-weight--bold;

  .icon {
    margin-right: math.div($spacing, 2);
    transform: translate3d(0, .2rem, 0);
  }
}

.auth__form {
  width: 100%;

  > .input {
    margin-bottom: $spacing;

    &:last-of-type {
      margin-bottom: 2.4rem;
    }
  }

  .button {
    justify-content: center;
    width: 100%;
    margin-bottom: 2.4rem;
  }
}

.auth__sublink {
  display: flex;
  justify-content: center;
  width: 100%;
  font-weight: $font-weight--bold;
  text-decoration: underline;
}

.auth__extra-info {
  margin: 2.4rem 0 auto;
  text-align: center;
}
