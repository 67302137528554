@use 'sass:math';

.header {
  color: $cl-white;
  background-color: $cl-tertiary;
  transition: background-color 300ms;

  &.is-inactive {
    background-color: $cl-grey-darker;

    .prefer-rating {
      color: $cl-grey-dark;
    }

    .header__back svg path {
      fill: $cl-white;
    }

    .navigation__button.is-active {
      background-color: $cl-grey-dark;
    }

    .rating__stars {
      background-color: $cl-grey-darker;
    }

    .rating__stars-innner {
      background-color: $cl-white;
    }

    .button--tertiary {
      color: $cl-grey-darker;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $cl-white;
  }

  .button--ghost {
    color: $cl-white;
    box-shadow: 0 0 0 .1rem rgba($cl-white, .5);

    &:hover,
    &:focus {
      box-shadow: 0 0 0 .1rem rgba($cl-white, 1)
    }

    .button__icon {
      path {
        fill: $cl-white;
      }
    }
  }

  .input__container {
    background-color: transparent;
    box-shadow: 0 .2rem .4rem rgba($cl-black, .02), 0 0 0 .1rem rgba($cl-white, .1) inset, .1rem .2rem .2rem rgba($cl-black, .04) inset;
  }
}

.header--secondary {

  .header__content {
    padding-bottom: 6.4rem;
  }
}

.header-bar {
  @extend %depth--header;
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 8rem;
  color: $cl-white;
  background-color: $cl-tertiary;

  .button--ghost {
    color: $cl-white;
    box-shadow: 0 0 0 .1rem rgba($cl-white, .5);

    &:hover,
    &:focus {
      box-shadow: 0 0 0 .1rem rgba($cl-white, 1)
    }

    .button__icon {
      path {
        fill: $cl-white;
      }
    }
  }

  .container {
    display: flex;
    align-items: center;
    height: 100%;
    box-shadow: 0 .1rem 0 0 rgba($cl-white, .25);
  }
}

.header__back {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.8rem;
  height: 4.8rem;
  margin-right: auto;
  box-shadow: 0 0 0 .1rem rgba($cl-white, .25);
  border-radius: $border-radius;
  transition: box-shadow $transition-duration;

  &:hover,
  &:focus {
    box-shadow: 0 0 0 .1rem $cl-white;
  }

  svg path {
    fill: $cl-white;
  }
}

.header__logo {
  display: flex;
  padding-right: $spacing;
}

.header__content {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4.8rem 0 11.2rem;
}

.header__content-right {
  display: flex;
  justify-content: flex-end;
  flex-basis: $column-percentage-width * 4;
  margin-left: auto;

  .input {
    min-width: 40rem;
    margin: 0;
  }

  .button {
    margin-left: $spacing;
  }
}

.header-bar__left,
.header-bar__middle,
.header-bar__right {
  display: flex;
  flex-basis: 33.33%;
}

.header-bar__left {
  align-items: center;
  height: 100%;
}

.header-bar__middle {
  justify-content: center;
}

.header-bar__right {
  justify-content: flex-end;

  .button {
    margin-left: $spacing;
  }
}

.header__heading {
  @extend %h2;
  display: flex;
  align-items: center;
  color: $cl-white;

  &.has-rating + .header__subheading {
    margin-left: 4rem + $spacing;
  }

  .prefer-rating + * {
    margin-left: $spacing;
  }
}

.header__subheading {
  @extend %h5;
  color: $cl-white;
}

.header-direction {
  display: flex;
  align-items: center;

  h4 {
    margin-bottom: math.div($spacing, 2);
  }

  h6 {
    opacity: .75;
  }
}

.header-direction__origin {
  margin-right: $spacing * 3;
}

.header-direction__destination {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: $spacing * 3;
}
