*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}

body,
html {
  margin: 0;
  // overflow-x: hidden;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 16px;
  overflow-y: scroll;
}

img {
  display: inline-block;
  max-width: 100%;
  height: auto;
  outline: none;
  border: 0;
}

svg {
  max-height: 100%;
}

input,
select,
textarea,
fieldset,
button {
  font-family: inherit;
  font-size: 1.6rem;
  border: 0;
  border-radius: 0;
  background: none;
}

button {
  cursor: pointer;
}

label {
  vertical-align: middle;
}

select {
  -webkit-appearance: none;
}

textarea {
  line-height: 1.75;
  padding: .5rem;
  vertical-align: top;
}

table {
  border-collapse: separate;
  border-spacing: 0;
  max-width: 100%;
  width: 100%;
}

th {
  text-align: left;
  font-weight: bold;
  vertical-align: bottom;
}

td {
  vertical-align: top;
}

th,
td {
  padding: .25rem 1rem;
  line-height: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  margin-bottom: 2rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

li {

  ul,
  ol {
    margin: 0 1rem;
  }
}

ul {
  list-style-type: disc;
  margin-left: 2rem;
}

ol {
  list-style-type: decimal;

  ol {
    list-style: lower-alpha;

    ol {
      list-style: lower-roman;

      ol {
        list-style: lower-alpha;
      }
    }
  }
}
