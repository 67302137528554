@keyframes slideDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  } to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideUp {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  } to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}

.notification-item {
  display: flex;
  margin-bottom: 0;
  text-align: center;
  box-shadow: none;
  border-radius: 0;
  border: none;

  &.animated {

    &.slideDown {
      animation: slideDown 400ms both;
    }

    &.slideUp {
      animation: slideUp 400ms both;
    }
  }
}

.notification-success {
  color: $cl-secondary;
  background-color: $cl-secondary-lightest;

  .notification-message {
    color: $cl-secondary;
  }

  .notification-message:before {
    background-image: url("../../images/icons/success.svg");
  }
}

.notification-danger {
  color: $cl-danger;
  background-color: $cl-danger-lightest;

  .notification-message {
    color: $cl-danger;
  }

  .notification-message:before {
    background-image: url("../../images/icons/danger.svg");
  }
}

.notification-info {
  color: $cl-default;
  background-color: $cl-default-lightest;

  .notification-message {
    color: $cl-default;
  }

  .notification-message:before {
    background-image: url("../../images/icons/info.svg");
  }
}

.notification-content {
  padding: 2rem $gutter !important;
}

.notification-message {
  display: flex;
  align-items: center;
  justify-content: center;
  font: {
    size: 1.6rem;
    weight: $font-weight--bold;
  }

  &:before {
    content: "";
    display: inline-block;
    flex-shrink: 0;
    width: 1.6rem;
    height: 1.6rem;
    margin-right: .8rem;
    background: {
      position: center;
      repeat: no-repeat;
    }
  }
}
