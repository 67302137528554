@use 'sass:math';

.ellipsis-button {
  position: relative;

  + * {
    margin-left: 1.6rem;
  }
}

.ellipsis-button__options {
  @extend %depth--dropdown;
  visibility: hidden;
  position: absolute;
  top: calc(100% + #{math.div($spacing, 2)});
  right: 0;
  width: 20.4rem;
  padding: .4rem;
  background-color: $cl-white;
  border-radius: .4rem;
  box-shadow: 0 .1rem .2rem rgba($cl-black, .04), 0 0 0 .1rem $cl-grey-alt inset;

  button {
    width: 100%;
    padding: 1.2rem 1.6rem;
    text-align: left;

    &:hover {
      background-color: $cl-grey-lighter;
    }

    + button {
      margin-top: .4rem;
    }
  }
}

.ellipsis-button__options--is-visible {
  visibility: visible;
}
