.suppliers {
  display: flex;
}

.suppliers-filter {
  margin-bottom: $spacing;

  &.is-visible {

    .suppliers-filter__content {
      max-height: 100vh;
      transition: {
        duration: 300ms;
        timing-function: ease-in-out;
      }
    }

    .suppliers-filter__icon {
      transform: rotate(-180deg);
    }
  }

  .rating {
    color: $cl-grey-dark;
    font-weight: $font-weight--bold;
  }

  .rating__stars {
    background-color: $cl-grey;
  }

  .rating__stars-inner {
    background-color: $cl-warning;
  }
}

.suppliers-filter__toggle {
  @extend %h5;
  display: flex;
  align-items: center;
  width: 100%;
  font-weight: $font-weight--bold;
  padding: $spacing 0;
  text-align: left;
  box-shadow: 0 .1rem 0 0 $cl-grey-light;
}

.suppliers-filter__icon {
  margin-left: auto;
  transition: transform 200ms;

  path {
    fill: $cl-black
  }
}

.suppliers-filter__content {
  overflow: hidden;
  max-height: 0;
  padding: 0 .1rem;
  transition: max-height 150ms cubic-bezier(0, 1, 0, 1);

  > * {
    margin: $spacing 0;
  }
}

.supplier-details {
  padding-bottom: 3.2rem;
}

.supplier-details__card-group {
  margin-top: $spacing;
}

.supplier-details__list-group {
  .group {
    max-width: unset;
  }

  li {
    display: flex;
    align-items: center;
    min-height: 4.8rem;
    margin-bottom: .8rem;
  }

  .card__column-subheading {
    display: block;
    width: 100%;
    margin-bottom: 0;
    padding-bottom: $spacing;
    border-bottom: .1rem solid transparentize($cl-black, .9);
  }

  + .supplier-details__list-group {
    margin-top: $spacing;
  }
}

.supplier-details__types {
  display: flex;
  flex-direction: column;
  text-transform: capitalize;
}
