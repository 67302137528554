.textarea {
  @extend %depth--content;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.textarea__top {
  display: flex;
  margin-bottom: 1rem;
}

.textarea__aside {
  margin-left: auto;

  .chip {
    padding: .4rem .8rem;
  }
}

a.textarea__aside {
  text-decoration: underline;
}

.textarea__element {
  width: 100%;
  min-height: 6.3rem;
  resize: vertical;
  padding: $spacing;
  border-radius: $border-radius;
  border: .1rem solid $cl-input-border;
  box-shadow: 0 .2rem .4rem rgba($cl-black, .02), inset .1rem .2rem .2rem rgba($cl-black, .04);
  outline: none;

  &:disabled {
    background-color: $cl-grey-lighter;
  }

  &:hover,
  &:focus {
    border-color: $cl-grey-dark;
  }
}

.textarea__label {
  font-weight: $font-weight--bold;

  span {
    color: $cl-danger;
  }
}
