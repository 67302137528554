@use 'sass:math';

.select {
  flex-grow: 1;
  max-width: 100%;

  &.has-error,
  &.is-dirty.is-invalid {

    .react-select__single-value {
      color: $cl-danger;
    }
  }

  + .input {
    margin-top: .8rem;
  }

  // We need to be specific to overwrite package styling
  .react-select__control {
    cursor: pointer;
    min-height: 4.8rem;
    border: none;
    background: none;
    box-shadow: none;
    border-radius: $border-radius;
  }

  .react-select__input {
    width: 100%;

    input {
      width: 100% !important;
      cursor: inherit;
    }
  }

  .react-select__control--is-disabled {
    background: $cl-grey-lighter;
    box-shadow: $input-shadow--disabled;
  }

  .react-select__option {
    cursor: pointer;
    white-space: pre-wrap;
  }

  .react-select__option--is-selected {
    color: $cl-primary;
    background: none;
  }

  .react-select__option--is-focused {
    background-color: $cl-grey-lighter;
    border-radius: .4rem;
  }

  .react-select__indicators {
    align-items: flex-start;
    padding-top: $spacing;
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__value-container {
    padding: .2rem 1.4rem;
    width: 100%;

    > * {
      flex-grow: 1;
    }
  }

  .react-select__value-container--is-multi {
    padding: .6rem;

    .react-select__placeholder {
      margin-left: .8rem;
    }
  }

  .react-select__menu {
    z-index: 100;
  }

  .react-select__menu-list {
    padding: .4rem;
  }

  .react-select__multi-value {
    display: flex;
    align-items: center;
    color: $cl-primary-lighter;
    background-color: $cl-primary-lightest;
    border-radius: .4rem;
  }

  .react-select__multi-value__label {
    order: 2;
    padding-right: 1.2rem;
    color: $cl-primary-lighter;
    font-weight: $font-weight--bold;
    border-radius: .4rem;
  }

  .react-select__multi-value__remove {
    order: 1;
    width: 1.6rem;
    height: 1.6rem;
    margin-left: 1.2rem;
    padding: 0;
    background: {
      image: url("../../../images/icons/select-delete.svg");
      repeat: no-repeat;
      size: 1.6rem;
    }
    border-radius: .4rem;

    &:hover {
      background-color: $cl-primary-lightest;
    }

    svg {
      display: none;
    }
  }

  .react-select__indicator {
    margin-right: 1.2rem;
    background: {
      repeat: no-repeat;
      size: 1.2rem;
      position: center;
    }

    svg {
      display: none;
    }
  }

  .react-select__clear-indicator {
    background: {
      size: 1.4rem;
      image: url("../../../images/icons/input-clear.svg");
    }
  }

  .react-select__dropdown-indicator {
    background: {
      image: url("../../../images/icons/select-chevron.svg");
    }
  }

  .react-select--is-disabled {
    .react-select__dropdown-indicator {
      opacity: .4;
    }
  }

  .results__meta & {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;

    .select__label {
      margin: 0 $spacing;
    }

    .react-select {
      flex-grow: 1;
    }
  }
}

.select__container {
  position: relative;
  display: flex;
  width: 100%;

  .react-select {
    width: 100%;
  }

  .input__prepend,
  .input__append {
    flex-shrink: 0;
  }

  > input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    opacity: 0;
  }
}

.select__label {
  display: flex;
  flex-shrink: 0;
  line-height: 1.25;
  margin-bottom: $spacing;
}

.select__label-element {
  white-space: nowrap;
  font-weight: $font-weight--bold;
}

.select__error-label {
  display: flex;
  align-items: center;
  padding-top: math.div($spacing, 2);
  color: $cl-danger;
  font-size: 1.4rem;
  line-height: 1;
  font-weight: $font-weight--bold;

  svg {
    margin-right: math.div($spacing, 2);
  }
}

.select__label-aside {
  display: flex;
  align-items: center;
  margin-left: .8rem;

  button {
    display: flex;
    align-items: center;
  }
}
