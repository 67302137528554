@keyframes fadeIn {
  from {
    opacity: 0;
  } to {
    opacity: 1;
  }
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0);
  } to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translate3d(0, 3rem, 0);
  } to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translate3d(0, -3rem, 0);
  } to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideLeft {
  from {
    opacity: 0;
    transform: translate3d(10rem, 0, 0);
  } to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideRight {
  from {
    opacity: 0;
    transform: translate3d(-10rem, 0, 0);
  } to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideDiagonalDownRight {
  from {
    opacity: 0;
    transform: translate3d(-100%, -100%, 0);
  } to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fanUpLeft {
  from {
    opacity: 0;
    transform: translate3d(100%, 100%, 0) rotate(45deg);
    transform-origin: bottom left;
  } to {
    opacity: 1;
    transform: translate3d(0, 0, 0) rotate(0);
    transform-origin: bottom left;
  }
}

