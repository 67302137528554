@use 'sass:math';

.checkbox {
  position: relative;
  display: inline-flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  margin-bottom: 1rem;

  + .checkbox {
    margin-left: 2.4rem;
  }

  &.is-disabled {
    cursor: default;

    .checkbox__element {
      background: $cl-grey-light;
      border-color: rgba($cl-black, .1);
    }

    .checkbox__label {
      color: $cl-grey-dark;
    }
  }

  &.is-bold {
    font-weight: $font-weight--bold;
  }
}

.input + .checkbox {
  margin-top: 1.6rem;
}

.checkbox--default {

  &.has-active-child .checkbox__element:after {
    background: url("../../../images/icons/checkmark-child.svg") center center no-repeat;
  }

  .checkbox__input {

    &:checked ~ .checkbox__element {
      background-color: $cl-primary-light;
      box-shadow: 0 .2rem .4rem rgba($cl-black, .04), 0 .1rem .2rem rgba($cl-black, .04), 0 0 0 .1rem rgba($cl-black, .1) inset;

      &:after {
        transform: scale(1);
      }
    }
  }

  .checkbox__element {
    position: relative;
    display: block;
    flex-shrink: 0;
    width: 1.6rem;
    height: 1.6rem;
    background-color: $cl-white;
    box-shadow: 0 .2rem .4rem rgba($cl-black, .04), 0 .1rem .2rem rgba($cl-black, .04), 0 0 0 .1rem $cl-input-border inset;
    border-radius: .4rem;
    transition: {
      property: background-color, box-shadow;
      duration: $transition-duration;
      timing-function: $cubic-bezier;
    };

    &:after {
      content: "";
      position: absolute;
      top: calc(50% - .3rem);
      left: calc(50% - .4rem);
      width: .8rem;
      height: .6rem;
      background: url("../../../images/icons/checkmark.svg") center center no-repeat;
      transform: scale(0);
      transition: transform $transition-duration $cubic-bezier;
    }
  }
}

.checkbox--switch {
  position: relative;

  .checkbox__label {
    margin-left: math.div($spacing, 2);
  }

  .checkbox__input {

    &:checked ~ .checkbox__element {
      background-color: $cl-primary-light;
      box-shadow: 0 .1rem .4rem rgba(81, 101, 128, .04), 0 0 0 .1rem rgba($cl-black, .1) inset;

      &:after {
        transform: translate3d(1.5rem, 0, 0);
      }
    }

    &:disabled {

      ~ .checkbox__element {
        background: $cl-grey-light;
        box-shadow: 0 .1rem .4rem rgba(81, 101, 128, .04), 0 0 0 .1rem rgba($cl-black, .1) inset;
      }
    }
  }

  .checkbox__element {
    position: relative;
    width: 4rem;
    height: 2.4rem;
    background: $cl-grey-lighter;
    box-shadow: 0 .1rem .4rem rgba(10, 17, 26, .04), 0 0 0 .1rem $cl-input-border;
    border-radius: 1.6rem;
    transition: {
      property: background-color, box-shadow;
      duration: $transition-duration;
      timing-function: $cubic-bezier;
    }

    &:after {
      content: "";
      position: absolute;
      top: .4rem;
      left: .4rem;
      display: block;
      width: 1.6rem;
      height: 1.6rem;
      background: $cl-white;
      box-shadow: 0 .2rem .4rem rgba($cl-black, .04), 0 .1rem .2rem rgba($cl-black, .04);
      border-radius: 50%;
      transition: transform $transition-duration $cubic-bezier;
    }
  }
}

.checkbox__label {
  margin-left: $spacing * .5;
  line-height: 1.25;
}

.checkbox__input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}
