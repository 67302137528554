.loader {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    animation: scaleIn 1000ms both;
  }
}

@keyframes scaleIn {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

.loader--full {
  width: 100vw;
  height: 100vh;
}

.loader--component {
  width: 100%;
  height: 100%;
  padding: $spacing;
}

.sk-folding-cube {
  margin: 20px auto;
  width: 40px;
  height: 40px;
  position: relative;
  transform: rotateZ(45deg);
}

.sk-folding-cube .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  transform: scale(1.1);
}
.sk-folding-cube .sk-cube:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $cl-primary;
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  transform-origin: 100% 100%;
}
.sk-folding-cube .sk-cube2 {
  transform: scale(1.1) rotateZ(90deg);
}
.sk-folding-cube .sk-cube3 {
  transform: scale(1.1) rotateZ(180deg);
}
.sk-folding-cube .sk-cube4 {
  transform: scale(1.1) rotateZ(270deg);
}
.sk-folding-cube .sk-cube2:before {
  animation-delay: 0.3s;
}
.sk-folding-cube .sk-cube3:before {
  animation-delay: 0.6s;
}
.sk-folding-cube .sk-cube4:before {
  animation-delay: 0.9s;
}
@-webkit-keyframes sk-foldCubeAngle {
  0%, 10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  } 25%, 75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  } 90%, 100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

@keyframes sk-foldCubeAngle {
  0%, 10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  } 25%, 75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  } 90%, 100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
