.styleguide {
  background-color: $cl-white;

  .group {
    margin-bottom: $gutter;
  }
}

.styleguide__section {
  padding: $gutter 0;
}

.styleguide__header {

  hr {
    margin: $spacing 0;
  }
}

.styleguide__content {
  padding-top: $gutter;
}

.styleguide__color {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 56%;
  border-radius: $border-radius;

  h4 {
    width: 100%;
    margin: 0 0 $spacing;
    color: $cl-white;
    text-align: center;
  }
}

[class^=styleguide__color-shade-] {
  flex-basis: 33.33%;
  height: 6.4rem;
}

.styleguide__color-primary {
  background-color: $cl-primary;

  .styleguide__color-shade-1 {
    background-color: $cl-primary-light;
  }

  .styleguide__color-shade-2 {
    background-color: $cl-primary-lighter;
  }

  .styleguide__color-shade-3 {
    background-color: $cl-primary-lightest;
  }
}

.styleguide__color-secondary {
  background-color: $cl-secondary;

  .styleguide__color-shade-1 {
    background-color: $cl-secondary-light;
  }

  .styleguide__color-shade-2 {
    background-color: $cl-secondary-lighter;
  }

  .styleguide__color-shade-3 {
    background-color: $cl-secondary-lightest;
  }
}

.styleguide__color-danger {
  background-color: $cl-danger;

  .styleguide__color-shade-1 {
    background-color: $cl-danger-light;
  }

  .styleguide__color-shade-2 {
    background-color: $cl-danger-lighter;
  }

  .styleguide__color-shade-3 {
    background-color: $cl-danger-lightest;
  }
}

.styleguide__color-warning {
  background-color: $cl-warning;

  .styleguide__color-shade-1 {
    background-color: $cl-warning-light;
  }

  .styleguide__color-shade-2 {
    background-color: $cl-warning-lighter;
  }

  .styleguide__color-shade-3 {
    background-color: $cl-warning-lightest;
  }
}

.styleguide__color-default {
  background-color: $cl-default;

  .styleguide__color-shade-1 {
    background-color: $cl-default-light;
  }

  .styleguide__color-shade-2 {
    background-color: $cl-default-lighter;
  }

  .styleguide__color-shade-3 {
    background-color: $cl-default-lightest;
  }
}
