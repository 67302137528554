.country-list {
  list-style: none;
  margin: 0;
}

.country-list__item {
  display: flex;
  align-items: center;
  margin: 1.6rem 0;

  .button {
    margin-left: auto;
  }
}

.country-list__item-continent {
  display: inline-block;
  opacity: .5;
  margin-left: .8rem;
}
