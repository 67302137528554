@use 'sass:math';

$button-box-shadow: 0 .1rem .4rem rgba(10, 17, 26, .16);

.button {
  cursor: pointer;
  display: flex;
  align-items: center;
  min-height: $default-height;
  font-weight: $font-weight--bold;
  line-height: 1;
  white-space: nowrap;
  padding: 1.25rem $spacing;
  border-radius: $border-radius;
  box-shadow: 0 0 0 .1rem rgba($cl-black, .1) inset;
  transition: {
    property: color, background-color, box-shadow;
    duration: $transition-duration;
  };

  &:disabled,
  &.is-disabled {
    cursor: default;
  }

  .button__icon {
    margin: 0 1.2rem 0 0;
  }

  span + .button__icon {
    margin: 0 0 0 1rem;
  }

  label {
    margin: 0 math.div($spacing, 2) 0 0;
  }

  .badge:last-child {
    margin-left: 1.2rem;
  }
}

.button--primary,
.button--secondary {
  color: $cl-white;
  box-shadow: $button-box-shadow, 0 0 0 .1rem rgba($cl-white, .1) inset;

  &:hover,
  &:focus {
    color: $cl-white;
  }

  &:disabled,
  &.is-disabled {
    color: $cl-grey-dark;
    background: $cl-disabled;
    box-shadow: none;

    &:hover,
    &:focus {
      color: $cl-grey-dark;
      background: $cl-disabled;
    }

    .button__icon path {
      fill: $cl-grey-dark;
    }
  }
}

.button--primary {
  background-color: $cl-primary-light;

  &:hover,
  &:focus {
    background-color: $cl-primary;
  }

  &.is-disabled-clickable {
    color: $cl-grey-dark;
    background: $cl-disabled;
    box-shadow: none;

    &:hover {
      background-color: $cl-primary;
      color: $cl-white;
    }

    .button__icon path {
      stroke: $cl-grey-dark;
    }
  }

  .button__icon {

    path {
      fill: $cl-white;
    }
  }
}

.button--secondary {
  background-color: $cl-secondary-light;

  &:hover,
  &:focus {
    background-color: $cl-secondary;
  }

  .button__icon {

    path {
      fill: $cl-white;
    }
  }
}

.button--tertiary {
  color: $cl-tertiary;
  background-color: $cl-white;
  transition: all $transition-duration;

  &:hover,
  &:focus {
    opacity: .8;
    box-shadow: 0 .2rem .4rem rgba($cl-black, .02), 0 0 0 .1rem rgba($cl-black, .7) inset, .1rem .2rem .2rem rgba($cl-black, .04) inset;
  }

  &:disabled,
  &.is-disabled {
    color: $cl-grey-dark;
    background: $cl-disabled;
    box-shadow: none;
    pointer-events: none;

    .button__icon path {
      stroke: $cl-grey-dark;
    }
  }
}

.button--icon {
  display: inline-block;
  color: $cl-black;
  background: none;
  border: none;
  box-shadow: none;
  padding: 0;
  width: 4.8rem;
  height: 3.2rem;
  flex-shrink: 0;
  margin: 0;
  margin-left: 1.2rem;

  .button__icon {
    margin: 0;

    path {
      stroke: $cl-black;
    }
  }

  &:hover {
    opacity: .6;
  }
}

.button--ghost {
  color: $cl-black;
  background: none;
  box-shadow: 0 .1rem .4rem rgba(10, 17, 26, .04), 0 0 0 .1rem rgba($cl-black, .1) inset;
  transition: box-shadow $transition-duration;

  &:hover,
  &:focus {
    color: $cl-black;
    box-shadow: 0 .1rem .4rem rgba(10, 17, 26, .04), 0 0 0 .1rem rgba($cl-black, .5) inset;
  }

  &:disabled {
    color: $cl-grey-dark;
    background: none;
    box-shadow: 0 .1rem .4rem rgba(10, 17, 26, .04), 0 0 0 .1rem rgba($cl-black, .1) inset;

    .button__icon path {
      stroke: $cl-grey-dark;
    }
  }

  .button__icon {

    path {
      stroke: $cl-black;
    }
  }
}

.button--danger {
  color: $cl-white;
  background-color: $cl-danger;
  transition: opacity $transition-duration;

  &:hover,
  &:focus {
    opacity: .9;
  }
}


.button--ghost-danger {
  color: $cl-danger;
  background-color: $cl-white;
  box-shadow: 0 .1rem .4rem rgba(10, 17, 26, .04), 0 0 0 .1rem rgba($cl-black, .1) inset;
  transition: {
    property: opacity, box-shadow;
    duration: $transition-duration;
  }

  &:hover,
  &:focus {
    box-shadow: 0 .1rem .4rem rgba(10, 17, 26, .04), 0 0 0 .1rem rgba($cl-black, .5) inset;
  }
}

.button--ghost-primary {
  color: $cl-primary;
  background-color: $cl-white;
  box-shadow: 0 .1rem .4rem rgba(10, 17, 26, .04), 0 0 0 .1rem rgba($cl-black, .1) inset;

  &:hover,
  &:focus {
    box-shadow: 0 .1rem .4rem rgba(10, 17, 26, .04), 0 0 0 .1rem rgba($cl-black, .5) inset;
  }
}

.button--text {
  color: $cl-secondary-light;
  background: none;

  &:hover,
  &:focus {
    color: $cl-secondary;

    .button__icon path {
      fill: $cl-secondary;
    }
  }

  &:disabled {
    color: $cl-grey-dark;

    &:hover,
    &:focus {
      color: $cl-grey-dark;
    }

    .button__icon path {
      fill: $cl-grey-dark;
    }
  }

  .button__icon {

    path {
      fill: $cl-secondary-light;
      transition: fill 200ms;
    }
  }
}

.button--full-width {
  justify-content: center;
  align-items: center;
  width: 100%;
}

.button--circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.8rem;
  height: 4.8rem;
  min-height: 0;
  border-radius: 50%;
  background: {
    color: $cl-white;
    position: 50%;
    repeat: no-repeat;
  }

  &.button--small {
    width: 3.2rem;
    height: 3.2rem;
    padding: 0;
  }
}

.link-button {
  color: $cl-primary;
  transition: color 200ms;
  cursor: pointer;

  &:hover {
    color: $cl-primary;
    text-decoration: underline;
  }
}
