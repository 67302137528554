.rate-requests__top {
  display: flex;
  margin: 0 (-$gutter) 0;
  padding: 0 $gutter;
  font-weight: $font-weight--bold;
  box-shadow: 0 .1rem 0 0 $cl-grey-light;

  .rate-requests__column {
    padding-bottom: $spacing;

    &:first-child {
      padding-left: 0;
    }
  }
}

.rate-requests__list {
  @extend .list--reset;
}

.rate-requests__item {
  display: flex;
  align-items: center;
  max-height: 20rem;
  height: 10rem;
  box-shadow: 0 .1rem 0 0 $cl-grey-light;
  transition: {
    property: opacity;
    duration: 500ms;
    timing-function: ease-in-out;
  }

  &:last-child {
    padding-bottom: 0;
    box-shadow: none;
  }

  .rate-requests__column:first-child {
    padding-left: 0;
  }

  .rate-requests__column:last-child {
    align-items: flex-end;
    justify-content: center;
    padding-right: 0;
  }
}

.rate-requests__item-enter {
  opacity: 0;
}

.rate-requests__item-enter-active {
  opacity: 1;
}

.rate-requests__item-exit {
  opacity: 1;
}

.rate-requests__item-exit-active {
  opacity: 0;
}

.rate-requests__column {
  display: flex;
  flex-direction: column;
  width: 20%;
  padding: 0 $spacing;
}

.rate-requests__column--head {
  flex-direction: row;
  align-items: center;
  user-select: none;
  cursor: pointer;

  .icon {
    margin-left: .8rem;

    path {
      fill: $cl-grey-darkest;
    }
  }
}

.rate-requests__secondary-label {
  width: 100%;
  color: $cl-grey-darkest;
  font-size: 1.4rem;
}

.rate-requests__reference {
  display: flex;
}

.rate-requests__reference-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.rate-requests__number {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  color: $cl-grey-darkest;
  font-size: 1.4rem;
}
