.tabbed-pages {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 3.2rem;

  .react-tabs__tab-list {
    position: sticky;
    top: 11.2rem;
    align-self: flex-start;
    list-style: none;
    grid-column-end: span 3;
    margin: 0;
    padding: 2.4rem 1.6rem;
    border: .1rem solid $cl-grey-light;
    border-radius: .8rem;
    background: $cl-white;
    box-shadow: 0px .2rem .8rem rgba($cl-black, 0.05);
  }

  .react-tabs__tab {
    line-height: 1.25;
    font-weight: $font-weight--bold;
    cursor: pointer;

    + .react-tabs__tab {
      margin-top: 2.4rem;
    }
  }

  .react-tabs__tab--selected {
    color: $cl-primary;
  }
}

.tabbed-pages__page {
  grid-column-end: span 9;
  padding: 3.2rem;
  border: .1rem solid $cl-grey-light;
  border-radius: .8rem;
  background: $cl-white;
  box-shadow: 0px .2rem .8rem rgba($cl-black, 0.05);

  hr {
    margin: $spacing 0 $spacing + 1rem;
  }
}
