.radio {
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;

  &.is-disabled {
    cursor: default;
  }
}

.radio__label {
  margin-left: $spacing
}

.radio__input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;

  &:checked {

    ~ .radio__element {
      background-color: $cl-primary-light;

      &:after {
        transform: scale(.4);
      }
    }
  }

  &:disabled {

    ~ .radio__element {
      background: $cl-grey-light;
      border-color: rgba($cl-black, .1);
    }

    ~ .radio__label {
      color: $cl-grey-dark;
    }
  }
}

.radio__element {
  position: relative;
  width: $spacing;
  height: $spacing;
  box-shadow: 0 .2rem .4rem rgba($cl-black, .04), 0 .1rem .2rem rgba($cl-black, .04), 0 0 0 .1rem $cl-input-border;
  border-radius: 50%;
  transition: background-color 200ms ease-in-out;

  &:after {
    content: "";
    position: absolute;
    top: calc(50% - .8rem);
    left: calc(50% - .8rem);
    width: 1.6rem;
    height: 1.6rem;
    background-color: $cl-white;
    border-radius: 50%;
    transform: scale(1);
    transform-origin: center center;
    transition: transform 200ms ease-in-out;
  }
}
