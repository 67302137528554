.modal {
  @extend %depth--modal;
  overflow: hidden;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: $spacing;
  transition: visibility 300ms;

  &.is-pending {

    .modal__overlay,
    .button {
      cursor: wait;
    }
  }

  &.is-visible {
    visibility: visible;

    .modal__overlay {
      opacity: 1;
      transition-timing-function: $cubic-bezier--in;
    }

    .modal__box {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      visibility: visible;
      pointer-events: auto;
      transition-timing-function: $cubic-bezier--in;
    }
  }

  ul:not(:last-child) {
    margin-bottom: 1.6rem;
    line-height: 1.4;
  }

  li + li {
    margin-top: .8rem;
  }
}

.modal--danger {

  .modal__box-content {
    min-height: auto;
  }
}

.modal--confirm {

  .modal__box {
    width: 42rem;
  }

  .modal__box-submit {
    display: flex;
    width: 100%;
    padding: 0 $gutter $spacing * 1.5;

    > .button {
      justify-content: center;
      width: 100%;
    }
  }

  .modal__box-meta {
    color: $cl-grey-dark;
    font-size: 1.4rem;
    text-align: center;
    padding: 0 $gutter $gutter;

    a {
      color: $cl-grey-dark;
      font-weight: $font-weight--bold;
      text-decoration: underline;
    }
  }
}

.modal--regular {

  .modal__box {
    width: 54rem;
  }
}

.modal--large {

  .modal__box {
    width: 100%;
    max-width: 74rem;
  }
}

.modal__overlay {
  @extend %depth--modal-overlay;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($cl-black, .4);
  transition: {
    property: opacity;
    duration: 300ms;
    timing-function: $cubic-bezier--out;
  }
}

.modal__box {
  @extend %depth--modal-box;
  opacity: 0;
  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  max-height: 100%;
  margin: auto 0;
  background-color: $cl-white;
  box-shadow: 0 .2rem .8rem rgba($cl-black, .05), 0 0 0 .1rem $cl-grey-light;
  border-radius: .8rem;
  transform: translate3d(0, 7.2rem, 0);
  visibility: hidden;
  pointer-events: none;
  transition: {
    property: opacity, transform, visibility;
    duration: 200ms;
    timing-function: $cubic-bezier--out;
  }
}

.modal__box--overflow {
  overflow: visible;

  .modal__box-content {
    overflow: visible;
  }
}

.modal__box-header {
  display: flex;
  align-items: center;
  padding: $gutter $gutter $spacing;

  .modal__close {
    align-self: flex-start;
    margin-left: auto;
    background: url('../../images/icons/cross.svg') 50% no-repeat;
    min-width: 3.2rem;
    min-height: 3.2rem;

    &:hover {
      opacity: .6;
    }
  }
}

.modal__box-header--no-padding-bottom {
  padding-bottom: 0;
}

.modal__box-content {
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 $gutter $gutter;

  // .modal__box--overflow & {
  //   overflow: visible;
  // }
}

.modal__box-footer {
  display: flex;
  justify-content: space-between;
  padding: 2.4rem $gutter;
  box-shadow: 0 -.1rem 0 0 $cl-grey-light;
}

.modal__subtitle {
  color: $cl-grey-darker;
}
